import { useQuery } from "@tanstack/react-query"
import { ReactNode, useEffect } from "react"
import { useParams } from "react-router-dom"

import LoadingSpinner from "@/components/Loading/LoadingSpinner"

import api from "@utilities/api.ts"
import { useInvalidateQuery } from "@utilities/invalidateQueries.ts"

import useAssignedSurveysStore from "../store/AssignedSurveysStore.ts"

const AssignedSurveysProvider = ({ children }: { children: ReactNode }) => {
  const invalidateInsightData = useInvalidateQuery(["assignedSurveysData"], 3000)
  const setCurrentAssignedSurveys = useAssignedSurveysStore((state) => state.setCurrentAssignedSurveys)
  const { clientId } = useParams()

  const {
    data: assignedSurveysDataResponse,
    isSuccess,
    isError,
    isLoading,
  } = useQuery({
    queryKey: ["assignedSurveysData", clientId],
    queryFn: async () => {
      const endpoint = `feed/client/${clientId}`
      const { data, status } = await api.get<AssignedSurveyResponse>(endpoint)
      if (status === 200) return data
      throw new Error("Fetching assigned surveys data failed")
    },
  })

  useEffect(() => {
    if (isSuccess && assignedSurveysDataResponse) {
      setCurrentAssignedSurveys(assignedSurveysDataResponse)
    }
  }, [assignedSurveysDataResponse, isSuccess, setCurrentAssignedSurveys])

  useEffect(() => {
    void invalidateInsightData()
  }, [clientId, invalidateInsightData])

  if (isLoading)
    return (
      <div className="my-6">
        <LoadingSpinner size="2x" />
      </div>
    )

  if (isError) return <div className="my-6">Could not load assigned surveys at this time</div>

  return children
}

export default AssignedSurveysProvider
