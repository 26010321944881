interface ProgressBarProps {
  percent: number
  bgColor?: string
  fgColor?: string
  textColor?: string
  size?: "small" | "medium" | "large"
}

const getHeightStyle = (size: "small" | "medium" | "large") => {
  switch (size) {
    case "small":
      return "h-1.5"
    case "medium":
      return "h-2.5"
    case "large":
      return "h-4"
  }
}

const BasicProgressBar = ({
  percent,
  bgColor = "neutral-100",
  fgColor = "black",
  textColor = "black",
  size = "medium",
}: ProgressBarProps) => {
  const heightStyle = getHeightStyle(size)

  return (
    <div className={"flex items-center"}>
      <div className={`w-full bg-${bgColor} rounded-full ${heightStyle}`}>
        <div className={`bg-${fgColor} ${heightStyle} rounded-full`} style={{ width: percent + "%" }}></div>
      </div>
      {!isNaN(percent) && <div className={`ml-2 text-${textColor}`}>{percent}%</div>}
    </div>
  )
}

export default BasicProgressBar
