import { create } from "zustand"

interface AssignedSurveysStore {
  currentAssignedSurveys: AssignedSurveyResponse | null
  setCurrentAssignedSurveys: (response: AssignedSurveyResponse) => void
}

const useAssignedSurveysStore = create<AssignedSurveysStore>((set) => ({
  currentAssignedSurveys: null,
  setCurrentAssignedSurveys: (currentAssignedSurveys) => set({ currentAssignedSurveys }),
}))

export default useAssignedSurveysStore
