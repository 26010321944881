import { Link } from "react-router-dom"

import BasicProgressBar from "@components/ProgressBars/BasicProgressBar.tsx"

import AssignmentAvailabilityDates from "@features/Assignment/AssignmentAvailabilityDates.tsx"

const InsightReportAssignmentCard = ({
  date_start,
  date_end,
  name,
  feed_item_interaction_id,
  completion,
}: ClientAssignment) => {
  const titleFontStyles = "text-md font-bold"

  return (
    <div className={"not-last:mb-2 border hover:shadow-xl p-4 rounded-lg duration-300 ease-in-out"}>
      {name === "Game" ? (
        <div>
          <div className={titleFontStyles}>{name}</div>
          <AssignmentAvailabilityDates available_from={date_start} available_to={date_end} />
          <div className={"my-2"}>
            <BasicProgressBar fgColor={"black"} percent={Math.round(completion * 100)} />
          </div>
        </div>
      ) : (
        <Link to={`/pr/responses/${feed_item_interaction_id}`}>
          <div className="flex flex-row">
            <div className="flex flex-col w-full">
              <div className={titleFontStyles}>{name}</div>
              <AssignmentAvailabilityDates available_from={date_start} available_to={date_end} />
            </div>
            <div className={"my-2 w-60"}>
              <BasicProgressBar size="small" fgColor={"black"} percent={Math.round(completion * 100)} />
            </div>
          </div>
        </Link>
      )}
    </div>
  )
}

export default InsightReportAssignmentCard
