import ClientsProvider from "@/providers/ClientsProvider.tsx"

import Card from "@components/Cards/Card"

import SectionContainer from "../atom/SectionContainer"
import AssignedSurveysCard from "./AssignedSurveysCard"
import SurveysProgressCard from "./SurveysProgressCard"
import AssignedSurveysProvider from "./provider/AssignedSurveysProvider"

const AssignmentInsightPage: React.FC = () => {
  return (
    <div className="flex flex-col items-center w-full">
      <div className="w-full">
        <h3>Assignments</h3>

        <SectionContainer title="Assigned Surveys" description="view assigned surveys for the next 7 days">
          <Card additionalClasses="border w-full shadow-none">
            <AssignedSurveysProvider>
              <AssignedSurveysCard />
            </AssignedSurveysProvider>
          </Card>
        </SectionContainer>
        <SectionContainer title="Surveys & Responses" description="view all assigned surveys and responses">
          <ClientsProvider>
            <SurveysProgressCard />
          </ClientsProvider>
        </SectionContainer>
      </div>
    </div>
  )
}

export default AssignmentInsightPage
