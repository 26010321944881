// utils.ts
export const nameToColor = (name: string): string => {
  let hash = 0
  const randomSeed = 200
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash)
  }

  const random = (seed: number) => (Math.sin(seed) * randomSeed) % 1

  const r = Math.floor(155 + random(hash) * 100)
  const g = Math.floor(155 + random(hash + 1) * 100)
  const b = Math.floor(155 + random(hash + 2) * 100)

  return `rgb(${r}, ${g}, ${b})`
}
