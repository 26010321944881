import { useState } from "react"

import { Tabs, TabsContent } from "@/shadcn-components/ui/tabs"

import InsightReportTabs from "./InsightReportTabs"
import ActivityPage from "./components/activity/ActivityPage"
import AssignmentInsightPage from "./components/assignment/AssignmentInsightPage"
import HabitPage from "./components/habit/HabitPage"
import InsightReportHeader from "./components/header/InsightReportHeader"
import MoodPage from "./components/mood/MoodPage"
import SummaryPage from "./components/summary/SummaryPage"
import SummaryHeaderProvider from "./components/summary/provider/SummaryHeaderProvider"
import SurveyPage from "./components/survey/SurveyPage"

const InsightReportPage: React.FC = () => {
  const [, setActiveTab] = useState("summary")
  return (
    <div className="flex flex-col items-center pb-8 w-full">
      <SummaryHeaderProvider>
        <InsightReportHeader />
      </SummaryHeaderProvider>

      <Tabs defaultValue="summary" onValueChange={(value) => setActiveTab(value)} className="w-full max-w-5xl">
        <div className="flex justify-center items-center">
          <InsightReportTabs />
        </div>

        <TabsContent value="summary">
          <SummaryPage />
        </TabsContent>

        <TabsContent value="activity">
          <ActivityPage />
        </TabsContent>

        <TabsContent value="surveys">
          <SurveyPage />
        </TabsContent>

        <TabsContent value="habits">
          <HabitPage />
        </TabsContent>

        <TabsContent value="mood">
          <MoodPage />
        </TabsContent>

        <TabsContent value="assignments">
          <AssignmentInsightPage />
        </TabsContent>
      </Tabs>
    </div>
  )
}

export default InsightReportPage
